import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackgroundSyncConstants } from "../../lib/background-sync";
import { openDB } from "idb";

export const entriesApi = createApi({
    reducerPath: "entriesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_STRAPI_BACKEND, prepareHeaders: async (headers, { endpoint }) =>
        {
            const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

            const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

            const accessToken = data[ 0 ]?.access_token;

            if (accessToken && !endpoint.includes('login') && !endpoint.includes("reset-password")) {
                headers.set('authorization', `Bearer ${accessToken}`);
            }
            db.close();
        }
    }),
    endpoints: (builder) => ({
        getEntryAnalysis: builder.query({
            query: (params) => ({
                url: "/entries/analytics",
                params,
            })
        }),
        getOverview: builder.query({
            query: (query) => `/entry-management/overview-cards?fields=${query.fields}&view=${query.view}&state_id=${query.state_id}&lga_id=${query.lga_id}&startDate=${query.startDate || ""}&endDate=${query.endDate || ""}`,

        }),
        getOverviewTable: builder.query({
            query: (filters: { fields: string, view?: string, state_id?: string, lga_id?: string, page?: number, limit?: number; }) => ({
                url: `/entry-management/overview-table?fields=${filters.fields}&view=${filters.view || "all"}&page=${filters.page}&limit=${filters.limit}&state_id=${filters.state_id}&lga_id=${filters.lga_id}`,
                method: "GET"

            })
        }),
        getOverviewChart: builder.query({
            query: (query) => ({
                url: `/entry-management/overview-chart?filters=${query.filters}&type=${query.type}&view=${query.view}&lga_id=${query.lga_id}&state_id=${query.state_id}&country_id=${query.country_id}&page=${query.page}`,
                method: "GET"
            })
        }),
        getAggregatedEntries: builder.query<any, { fields: string, view: string, state_id?: string, lga_id?: string, page?: number, limit?: number; locationType?: string; sectorType?: string; schoolType?: string; startDate?: string | null; endDate?: string | null; }>({
            query: (query) => `/entry-management/aggregated?fields=${query.fields}&view=${query.view}&page=${query.page}&limit=${query.limit}&state_id=${query.state_id}&lga_id=${query.lga_id}&location_type=${query.locationType || ""}&sector_type=${query.sectorType || ""}&school_type=${query.schoolType || ""}&startDate=${query.startDate || ""}&endDate=${query.endDate || ""}`
        }),
        getUserEntries: builder.query<any, { id: string, query?: string; }>({
            query: (payload) => `/entries?filters[user_created][id][$eq]=${payload.id}&populate[ward][fields][0]=name&populate[local_government][fields][0]=name&populate[state][fields][0]=name&populate[school][fields][1]=name&fields[0]=status&fields[1]=createdAt`
        }),
        getEntriesDetails: builder.query({
            query: (id) => `/entries/${id}?populate[ward][fields][0]=name&populate[local_government][fields][0]=name&populate[state][fields][0]=name&populate[school][fields][1]=name&&populate[school][fields][2]=sector_type`
        }),
        getEntries: builder.query<any, any>({
            query: (query: string) => `/entries?populate[ward][fields][0]=name&populate[local_government][fields][0]=name&populate[state][fields][0]=name&populate[school][fields][1]=name&populate[user_created][fields][0]=first_name&fields[0]=status&fields[1]=createdAt&${query}`
        }),

        changeEntryStatus: builder.mutation({
            query: (payload) => ({
                url: `/entries/${payload.id}`,
                method: "PUT",
                body: {

                    data: {
                        ...payload.body
                    }
                }
            })
        }),
        updateEntry: builder.mutation({
            query: (payload) => ({
                url: `/entries/${payload.id}`,
                method: "PUT",
                body: {
                    data: {
                        ...payload.body
                    }
                }
            })
        }),
        createEntry: builder.mutation({
            query: (data) => ({
                url: `/entries`,
                method: "POST",
                body: {
                    data: { ...data }
                }
            })
        })
    })
});

export const {
    useGetOverviewQuery,
    useGetEntryAnalysisQuery,
    useGetOverviewTableQuery,
    useGetOverviewChartQuery,
    useGetAggregatedEntriesQuery,
    useGetUserEntriesQuery,
    useGetEntriesDetailsQuery,
    useGetEntriesQuery,
    useChangeEntryStatusMutation,
    useUpdateEntryMutation,
    useCreateEntryMutation,
} = entriesApi;
