import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { openDB } from 'idb';
import { BackgroundSyncConstants } from '../../lib/background-sync';

export const financialApi = createApi({
    reducerPath: 'financialApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_STRAPI_BACKEND,
        prepareHeaders: async (headers, { endpoint }) =>
        {
            const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

            const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

            const accessToken = data[ 0 ]?.access_token;

            if (accessToken && !endpoint.includes('login') && !endpoint.includes('reset-password')) {
                headers.set('authorization', `Bearer ${accessToken}`);
            }
            db.close();
        },
    }),
    endpoints: (build) => ({
        getSubebExpenditure: build.query<any, string>({
            query: (payload) => `/subeb-education-expenditures?populate=deep&sort=year:desc${payload ? `${payload}` : ""}`,
        }),
        addSubebExpenditure: build.mutation<any, any>({
            query: (body) => ({
                url: "/subeb-education-expenditures",
                method: "POST",
                body: { data: body }
            }),
        }),
        getSingleSubebExpenditure: build.query<any, string>({
            query: (id) => `/subeb-education-expenditures/${id}?populate=deep`,
        }),
        deleteSubebExpenditure: build.mutation<any, string>({
            query: (id) => ({
                url: `/subeb-education-expenditures/${id}`,
                method: "DELETE",
            }),
        }),
        updateSubebExpenditure: build.mutation<any, any>({
            query: (body) => ({
                url: `/subeb-education-expenditures/${body.id}`,
                method: "PUT",
                body: { data: { ...body.data } }
            }),
        }),
        getSmoeExpenditure: build.query<any, string>({
            query: (payload) => `/smoe-education-expenditures?populate=deep&sort=year:desc${payload ? `${payload}` : ""}`,
        }),
        addSmoeExpenditure: build.mutation<any, any>({
            query: (body) => ({
                url: "/smoe-education-expenditures",
                method: "POST",
                body: { data: body }
            }),
        }),
        getSingleSmoeExpenditure: build.query<any, string>({
            query: (id) => `/smoe-education-expenditures/${id}?populate=deep`,
        }),
        deleteSmoeExpenditure: build.mutation<any, string>({
            query: (id) => ({
                url: `/smoe-education-expenditures/${id}`,
                method: "DELETE",
            }),
        }),
        updateSmoeExpenditure: build.mutation<any, any>({
            query: (body) => ({
                url: `/smoe-education-expenditures/${body.id}`,
                method: "PUT",
                body: { data: { ...body.data } }
            }),
        }),
        getFinancialAnalysis: build.query<any, any>({
            query: (params) => ({
                url: `entries/analytics/financial`,
                params,

            })
        })

    })
});


export const {
    useGetSubebExpenditureQuery,
    useAddSubebExpenditureMutation,
    useGetSingleSubebExpenditureQuery,
    useDeleteSubebExpenditureMutation,
    useUpdateSubebExpenditureMutation,
    useGetSmoeExpenditureQuery,
    useAddSmoeExpenditureMutation,
    useGetSingleSmoeExpenditureQuery,
    useDeleteSmoeExpenditureMutation,
    useUpdateSmoeExpenditureMutation,
    useGetFinancialAnalysisQuery,
} = financialApi;
